$sw-font-family: Roboto, sans-serif !default;
$sw-foreground: #454545 !default;
$sw-background1: #f2f2f2 !default;
$sw-background2: #ffffff !default;
$sw-bgGrey: #d7d7d7 !default;
$sw-primary: #ceff00 !default;
$sw-secondary: #222222 !default;
$sw-accent: #2f80ed !default;
$sw-primaryBg: linear-gradient(180deg, $sw-primary 60.77%, $sw-primary 60.79%, #b3dd00 229.23%) !default;
$sw-secondaryBg: linear-gradient(180deg, #666666 15%, #525252 120%) !default;
$sw-padding: 30px !default;
$sw-headerHeight: 100px !default;
