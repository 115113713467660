@import '~stylewhere/theme';

// Modifica variabili di react-datepicker/src/stylesheets/variables.scss
$datepicker__font-family: $sw-font-family;
$datepicker__selected-color: $sw-secondary;

@import '~react-datepicker/src/stylesheets/datepicker.scss';

.chakra-portal .react-datepicker,
.chakra-portal .react-datepicker__month-container {
  width: 100%;
}

.chakra-portal .react-datepicker {
  margin-top: 0px;
  border-radius: 10px !important;
  border: 0px;
}

.chakra-portal .react-datepicker__navigation {
  height: 50px;
  width: 50px;
  top: 8px;
}

.chakra-portal .react-datepicker__month-read-view--down-arrow,
.chakra-portal .react-datepicker__navigation-icon::before {
  height: 18px;
  width: 18px;
}

.chakra-portal .react-datepicker__year-read-view--down-arrow,
.chakra-portal .react-datepicker__month-year-read-view--down-arrow {
  height: 16px;
  width: 16px;
}

.chakra-portal .react-datepicker__current-month,
.chakra-portal .react-datepicker-time__header,
.chakra-portal .react-datepicker-year-header {
  font-size: 26px;
  text-transform: uppercase;
  line-height: 50px;
}

.chakra-portal .react-datepicker__day-names {
  margin-top: 8px;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.chakra-portal .react-datepicker__day-name,
.chakra-portal .react-datepicker__day,
.chakra-portal .react-datepicker__time-name {
  width: calc(14.28% - 4px);
  font-size: 18px;
  line-height: 30px;
  margin: 2px;
}

.chakra-portal .react-datepicker__day {
  font-size: 20px;
  line-height: 50px;
}

.react-datepicker__year-read-view--selected-year {
  font-size: 18px;
  margin-right: 10px;
}

.react-datepicker__year-option {
  font-size: 18px;
  line-height: 30px;
}

.react-datepicker__close-icon {
  padding: 0;
  position: absolute;
  top: 0;
  right: 20px;

  &::after {
    height: 24px;
    width: 24px;
    padding: 0;
    font-size: 24px;
  }
}
